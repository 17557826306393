<template>
  <div :class="{ 'popover-active': isActive }">
    <Transition name="fade">
      <div v-if="isActive" class="backdrop" />
    </Transition>
    <div
      class="container"
      @mouseover="isActive = true"
      @mouseleave="isActive = false"
    >
      <Transition name="fade">
        <div v-if="isActive" class="content-container">
          <div class="content-body">
            <button
              class="close"
              :aria-label="t('close')"
              @click="isActive = false"
            >
              <CloseIcon />
            </button>
            <slot />
          </div>
          <TooltipPointerIcon class="pointer-icon" />
        </div>
      </Transition>
      <button :aria-label="t('learn-more')" @click="onButtonClick">
        <InfoIcon class="info-icon" />
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import CloseIcon from '@/assets/icons/close-thin.svg?component'
import InfoIcon from '@/assets/icons/info.svg?component'
import TooltipPointerIcon from '@/assets/icons/tooltip-pointer.svg?component'

const isDesktop = useIsDesktop()
const { t } = useI18n()

const isActive = ref(false)

const onButtonClick = () => {
  if (isDesktop.value) return

  isActive.value = !isActive.value
}
</script>

<style lang="scss" scoped>
.backdrop {
  @include hide-up(lg);
  position: fixed;
  inset: 0;
  z-index: 3;
  background-color: rgba(black, 0.5);
}

.container {
  position: relative;

  .popover-active & {
    z-index: 3;
  }
}

.content-container {
  position: absolute;
  right: 100%;
  bottom: 0;
  display: flex;
  align-items: flex-end;
}

.pointer-icon {
  flex-shrink: 0;
  width: rem(14px);
  margin-bottom: rem(10px);
  margin-left: rem(-5px);
  color: $pure-white;
  transform: rotate(90deg);
}

.close {
  @include hide-up(lg);
  position: absolute;
  top: 0;
  right: 0;
  padding: rem(6px) rem(11px);
  background: $pure-white;
  border-radius: 50%;
  box-shadow: $base-shadow-light;
  transform: translate(50%, -50%);
}

.content-body {
  @include font-size(
    (
      xs: 16px,
      lg: 12px
    ),
    1.24
  );
  position: relative;
  width: rem(281px);
  padding: rem(16px) rem(50px) rem(50px) rem(21px);
  font-weight: 500;
  color: $navy-blue;
  letter-spacing: -0.025em;
  background-color: $pure-white;
  border-radius: rem(2px);

  @include media-up(lg) {
    width: rem(193px);
    padding: rem(10px) rem(24px) rem(31px) rem(14px);
    filter: drop-shadow($base-shadow-light);
  }

  .contrast-mode & {
    color: $contrast-yellow;
  }
}

.info-icon {
  color: $navy-blue;

  .contrast-mode & {
    --background-color: #{$contrast-black};
    color: $contrast-yellow;
  }
}
</style>
